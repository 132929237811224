div#progress-multi-color-bar {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
}

.progress-component {
  background: red;
  height: 15px;
}

.progress-component.review {
  width: 20%;
  background: #FBC229;
  border-radius: 0px 10px 10px 0px;
}

.progress-component.in-progress {
  width: 40%;
  background: #7683F6;
}

.progress-component.done {
  background: #44E47A;
  width: 60%;
  border-radius: 10px 0px 0px 10px;
}


div#progress-multi-color-status-hint {
  display: flex;
  margin-top: 15px;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
}

div#progress-multi-color-status-hint span {
  margin-left: 7px;
  font-size: 12px;
}

.progress-hint {
  width: 10px;
  height: 10px;
  display: flex;
  margin-left: 8px;
  border-radius: 15px;
}

.progress-hint.in_progress{
  background: #7683F6;
}
.progress-hint.review{
  background: #FBC229;
}
.progress-hint.pending{
  background: #FBC229;
}
.progress-hint.done{
  background: #44E47A;
}

.demands-list ul {
  list-style: none;
  padding: 0;
  height: 120px;
  overflow-y: auto;
}

.demands-list ul li {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 15px;
}

li.list-item-demands-dp span {
  margin-left: 8px;
}

.list-item-demands-dp-img {
  margin-left: 20px;
}
