.proposals-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: #FFFFFF;
  gap: 50px;
  padding: 34px 0;
}
.proposals-header-wrapper {
  width: 100%;
  min-height: 0;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: 25px;
}
.proposals-header-inner-wrapper{
  width: 100%;
  min-height: 0;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
  gap: 12px;
}
.proposals-inner-wrapper-header-section {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-bottom: 40px;
}
.proposals-inner-wrapper-search{
  width: 60%;
}
