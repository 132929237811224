.modal-bonuz-plans-presentation {
    overflow-y: auto;
    background: #FFFFFF;
    box-shadow: 0px 2px 8px 2px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    text-align: left;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 20px 32px;
}
.modal-bonuz-plans-presentation h4 {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  color: #4E4B59;
  margin: 0;
  padding-bottom: 12px;
}
.modal-bonuz-plans-presentation-details{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 0;
  padding-top: 20px;
  gap: 10px;
}
.modal-bonuz-plans-presentation-details p{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #4E4B59;
  margin: 0;
  padding: 0;
}
.modal-bonuz-plans-presentation-details b{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  color: #4E4B59;
  margin: 0;
  padding: 0;
}
