.client-balance-wrapper{
  height: 210px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  flex-grow: 1;
  padding: 34px;
  background: #FFFFFF;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
}

.client-balance-left-container{
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;  
  font-family: Montserrat;
  font-size: 28px;
  font-weight: 600;
  text-align: left;
  color: #4E4B59;
}

.client-balance-right-container{
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  text-align: right;
  color: #9997A0;
  padding-left: 28px;
}

.client-balance-inner-container{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-family: Montserrat;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  text-align: right;
  color: #F14F5C;
}