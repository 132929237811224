
@keyframes changeColor {
  0% { background-image: linear-gradient(45deg, #3dbd7d, #44e47a); } /* Gradiente inicial */
  50% { background-image: linear-gradient(45deg, #44e47a, #3dbd7d); } /* Gradiente intermediário */
  100% { background-image: linear-gradient(45deg, #3dbd7d, #44e47a); } /* Gradiente final */
}

.services {
  color: #4e4b59;
  font-family: 'Montserrat';
  font-size: 24px;
  font-weight: 500;
  line-height: 29.76px;
  text-align: left;
  white-space: nowrap;
  letter-spacing: -0.24px;
}

.open-demand {
  display: flex;
  height: 48px;
  color: #ffffff;
  font-family: 'Inter';
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  text-align: center;
  white-space: nowrap;
  background: #192c50;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 8px;
}

.open-demand-premium {
  display: flex;
  height: 48px;
  color: #ffffff;
  font-family: 'Inter';
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  text-align: center;
  white-space: nowrap;
  background: #000000;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 8px;
}

.open-service {
  display: flex;
  height: 48px;
  color: #ffffff;
  font-family: 'Inter';
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  text-align: center;
  white-space: nowrap;
  background-color: #FB6D06;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 8px;
}

.open-service-premium {
  display: flex;
  height: 48px;
  color: #000000;
  font-family: 'Inter';
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  text-align: center;
  white-space: nowrap;
  background-color: #F6BD00;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 8px;
}

.open-upgrade {
  display: flex;
  height: 48px;
  color: #ffffff;
  font-family: 'Inter';
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  text-align: center;
  white-space: nowrap;
  background-color: #00E676;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 8px;
}

.bonuz-cards {
    overflow: hidden;
  }
  
  .bonuz-cards,
  .bonuz-cards * {
    box-sizing: border-box;
  }
  
  .bonuz-cards {
    position: relative;
    width: 160px;
    height: 182px;
    margin-right: 32px;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    background: #ffffff;
  }
  .bonuz-cards .group-two {
    position: relative;
    width: 16px;
    height: 16px;
    margin: 8px 0 0 8px;
    background-size: cover;
    z-index: 99;
  }
  .bonuz-cards .card-frame {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    gap: 16px;
    position: relative;
    width: 128px;
    margin: -8px 0 0 16px;
  }
  .bonuz-cards .card-one {
    position: relative;
    width: 32px;
    height: 26px;
    overflow: hidden;
  }
  .bonuz-cards .card-two {
    position: relative;
    width: 32px;
    height: 26px;
  }
  .bonuz-cards .card-text {
    position: relative;
    color: #9997a0;
    font-family: 'Inter';
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    letter-spacing: -0.16px;
  }
  .bonuz-cards .card-text-2 {
    position: relative;
    color: #9997a0;
    font-family: 'Inter';
    font-size: 12px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    letter-spacing: -0.12px;
  }

  .bonuz-cards .card-description {
    flex-shrink: 0;
    position: relative;
    width: 102px;
    font-family: 'Inter';
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    letter-spacing: -0.16px;
  }

  .bonuz-cards .card-description-one {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-shrink: 0;
    position: relative;
    width: 128px;
    height: 53px;
    color: #9997a0;
    font-family: Inter, var(--default-font-family);
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: center;
    letter-spacing: -0.16px;
  }

  .bonuz-cards .card-comprar{
    display: flex;
    align-items: flex-start;
    justify-content: center;
    position: relative;
    width: 83px;
    height: 24px;
    margin: 12px 0 0 38px;
    font-family: 'Inter';
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: center;
    text-decoration: underline;
    text-transform: uppercase;
    white-space: nowrap;
    letter-spacing: -0.16px;
    border: none;
    background-color: transparent;
  }

  .bonuz-cards .card-solicitar {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    position: relative;
    width: 83px;
    height: 24px;
    margin: 32px 0 0 38px;
    font-family: 'Inter';
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: center;
    text-decoration: underline;
    text-transform: uppercase;
    white-space: nowrap;
    letter-spacing: -0.16px;
    z-index: 2;
    border: none;
    background-color: transparent;
  }

  .card-andamento {
    position: relative;
    width: 734px;
    height: 94px;
    font-size: 0px;
    border-radius: 8px;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
    background: #ffffff;
  }

  .card-btn-upgrade {
    display: flex;
    align-items: flex-start;
    flex-wrap: nowrap;
    gap: 10px;
    position: relative;
    width: 120px;
    margin: 18.13px 0 0 20px;
    padding: 8px 27px 8px 27px;
    cursor: pointer;
    background: #44e47a;
    border: none;
    border-radius: 8px;
    justify-content: center;
    color: #ffffff;
    font-family: 'Inter';
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: center;
    text-transform: capitalize;
    white-space: nowrap;
    letter-spacing: -0.16px;
  }

  .card-btn-upgrade:hover {
    animation: changeColor 3s infinite; 
  }

  .andamento-geral {
    display: block;
    position: relative;
    height: 27px;
    margin: 10px 0 0 28.56px;
    color: #4e4b59;
    font-family: 'Inter';
    font-size: 18px;
    font-weight: 500;
    line-height: 27px;
    text-align: left;
    white-space: nowrap;
    letter-spacing: -0.09px;
    border-radius: 8px;
  }

  .card-andamento .success {
    position: relative;
    height: 8px;
    background: #44e47a;
    border-radius: 2px 0 0 2px;
    display: inline-block;
  }

  .card-andamento .in-progress {
    position: relative;
    height: 8px;
    background: #7683F6;
    border-radius: 2px 0 0 2px;
    display: inline-block;
  }

  .card-andamento .warning {
    position: relative;
    height: 8px;
    background: #FBC229;
    border-radius: 2px 0 0 2px;
    display: inline-block;
  }

  .card-bar {
    padding-top: 8px;
    padding-left: 28px;
    width: 80%;
  }

  .card-andamento .legend .status {
    color: #4e4b59;
    font-family: 'Inter';
    font-size: 10px;
    font-weight: 500;
    line-height: 15px;
    text-align: left;
    white-space: nowrap;
    letter-spacing: -0.05px;
    padding-right: 10px;
    border: none;
    background-color: transparent;
  }

  .card-andamento .legend {
    padding-top: 15px;
    padding-left: 28px;
  }

  .card-demands {
    position: relative;
    width: 400px;
    height: 94px;
    background: #ffffff;
    border-radius: 8px;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
    padding-top: 10px;
    padding-left: 15px;
  }

  .card-demands .demand {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    position: absolute;
    height: 27px;
    color: #4e4b59;
    font-family: 'Inter';
    font-size: 18px;
    font-weight: 500;
    line-height: 27px;
    text-align: left;
    white-space: nowrap;
    letter-spacing: -0.09px;
  }

  .card-demands .details {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    position: absolute;
    height: 21px;
    top: 65px;
    left: 289px;
    color: #7683f6;
    font-family: Inter, var(--default-font-family);
    font-size: 14px;
    font-weight: 700;
    line-height: 21px;
    text-align: left;
    white-space: nowrap;
    border: none;
    background-color: transparent;
  }

  .card-demands .icon {
    position: absolute;
    width: 32px;
    height: 32px;
    top: 13px;
    left: 344px;
    background-size: cover;
  }

  .card-demands .number {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    position: absolute;
    height: 36px;
    top: 47px;
    left: 14px;
    color: #f14f5c;
    font-family: 'Inter';
    font-size: 24px;
    font-weight: 700;
    line-height: 36px;
    text-align: left;
    white-space: nowrap;
    letter-spacing: -0.12px;
  }
  .color-plano-essencial {
    color: #fa6c05;
  }

  .border-plano-essencial {
    border-bottom: 2px solid #fa6c05;
  }

  .color-plano-premium, .color-plano-platinum {
    color: #000000;
  }
  .border-plano-premium, .color-plano-platinum {
    border-bottom: 2px solid #000000;
  }

  .color-plano-start {
    color: #000000;
  }
  .border-plano-start {
    border-bottom: 2px solid #874687;
  }
  
  .entrar-em-contato, .entrar-em-contato:hover {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    position: relative;
    width: 115px;
    height: 48px;
    margin: 9px 0 0 22px;
    color: #000000;
    font-family: Inter, var(--default-font-family);
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: center;
    text-decoration: underline;
    text-transform: uppercase;
    letter-spacing: -0.16px;
    border: none;
    background-color: transparent;
  }

