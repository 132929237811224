span#old-price-plans {
  color: gray;
  text-decoration: line-through;
  margin-right: 15px;
}

div#final-price-plans {
  display: flex;
  justify-content: flex-start;
}

div#hint-discount-credits {
  margin-left: 10px;
}

div#hint-discount-credits button {
  height: 31px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  font-size: 12px;
  font-weight: bold;
  z-index: 9999;
}

div#final-price-plans {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
}

p.tooltip-text-finalprice strong {
  color: #fff !important;
}
