#service-requester h3{
  text-align: center;
  margin-bottom: 40px;
}

#service-requester #loader {
  height: 300px;
}

div#service-requester #loader .card-body {
  display: flex;
  justify-content: center;
}

