.chatv3 {
    width: 100%;
    height: 100%;
}

.chatv3 .list-chat {
    width: 100%;
    height: 85%;
    overflow: auto;
    padding: 15px;
    height: 45vh;
}

.chatv3 .send-message {
    width: 100%;
    height: 15%;
    padding: 15px;
}

.chatv3 .message-date {
    width: 100%;
    text-align: center;
}

.chatv3 .message-date span {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 150%;
    letter-spacing: -0.005em;
    color: #7683F6;
    padding: 4px 8px;
    border: 1px solid #7683F6;
    border-radius: 32px;
}

.chatv3 .message-client {
    text-align: right;
}

.chatv3 .message-client .message {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 16px;
    gap: 10px;
    background: #F14F5C;
    border-radius: 8px 8px 0px 8px;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 150%;
    letter-spacing: -0.005em;
    color: #FFFFFF;
    display: inline-block;
    margin-bottom: 3px;
}

.chatv3 .hour {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: -0.005em;
    color: #9997A0;
    display: inline-block;
}

.chatv3 .message-lawyer {
    text-align: left;
}

.chatv3 .message-lawyer .message {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 16px;
    gap: 10px;
    background: #E8E8E8;
    border-radius: 8px 8px 8px 0px;
    flex: none;
    order: 0;
    flex-grow: 0;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 150%;
    letter-spacing: -0.005em;
    color: #000000;
    width: auto;
    display: inline-block;
    margin-bottom: 3px;
}

.chatv3 .message-attatch-v3 {
    padding: 3px;
    border: 1px dashed #9997A0;
    color: #9997A0;
    background-color: #FFF;
    border-radius: 8px;
}
input#outlined-adornment-message-v3 {
    height: 100% !important;
    background: transparent;
    border: 0;
    align-items: self-start;
}