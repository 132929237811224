div#service-type-option {
  text-align: center;
}

div#service-type-option .card {
  padding: 30px;
}

div#service-type-options-buttons {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

div#service-type-options-buttons button {
  width: 150px;
}

.signBlue {
  background-color: rgb(86, 86, 252) !important;
}
