.purchaseBnz-wrapper{
  width: 50vw;
  max-height: 80vh;
  display: inline-block;
  background-color: #FFFFFF;
  overflow: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0px 0px 10px rgb(0 0 0 / 10%);
  padding: 32px;
  border-radius: 8px;
  flex-wrap: wrap;
}

p.discount-orange span {
  font-size: 24px;
  color: #FB6D06;
}

p.discount-yellow span {
  font-size: 24px;
  color: #F6BD00;
}

button.upgrade-plan-essencial {
  margin-left: 20px;
  padding: 10px;
  background: #FB6D06;
  color: #fff;
  font-weight: bold;
  border: none;
  border-radius: 5px;
}

button.upgrade-plan-premium {
  margin-left: 25px;
  padding: 10px;
  background: #000;
  color: #F6BD00;
  font-weight: bold;
  border: none;
  border-radius: 5px;
}

button.upgrade-plan-start {
  margin-left: 25px;
  padding: 10px;
  background: #b34db2;
  color: #000000;
  font-weight: bold;
  border: none;
  border-radius: 5px;
}

button.upgrade-plan-platinum {
  margin-left: 25px;
  padding: 10px;
  background: #00008b;
  color: #FFFFFF;
  font-weight: bold;
  border: none;
  border-radius: 5px;
}

.purchaseBnz-title{
  width: 100%;
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 700;
  text-align: left;
  padding: 0;
  margin: 0;
  color: #4E4B59;
}

.purchaseBnz-hint-section{
  width: 100%;
  margin: 20px 0 0 0;
}

.purchaseBnz-header-section{
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
  flex-wrap: wrap;
  padding-top: 20px;
}

.purchaseBnz-header-section-left-container{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 21px;
  padding-top: 16px;
}

.purchaseBnz-header-section-inner-wrapper{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}

.purchaseBnz-header-section-field-label{
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  text-align: left;
  color: #4E4B59;
}

.purchaseBnz-header-section-field-helper-text{
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  text-align: left;
  color: #D3D2D7;
}

.purchaseBnz-header-section-right-container{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;  
  background: #FAFAFA;
  border-radius: 12px;
  padding: 15px 56px 22px 21px;
}

.purchaseBnz-header-section-right-container-title{
  font-family: Inter;
  font-size: 16px;
  font-weight: 700;
  text-align: left;
  color: #4E4B59;
}

.purchaseBnz-header-section-right-container-price{
  font-family: Inter;
  font-size: 24px;
  font-weight: 400;
  text-align: left;
  color: #F14F5C;
  padding: 17px 14px 0 0;
}

.purchaseBnz-middle-section{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding-top: 26px;
  font-family: Inter;
  font-size: 18px;
  font-weight: 700;
  text-align: left;
  color: #4E4B59;
  gap: 5px;
}

.purchaseBnz-middle-section-inner-wrapper{
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  gap: 10px;
}

.purchaseBnz-middle-section-left-container{
  width: 45%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}

.purchaseBnz-middle-section-right-container{
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  color: #FFFFFF;
  background: #7683F6;
  border-radius: 16px;
  padding: 12px 24px 0 24px;
}

.purchaseBnz-package-description-text{
  font-family: Inter;
  font-weight: 400;
  font-size: 16px;
  text-align: left;
  padding-bottom: 12px;
}

.purchaseBnz-bottom-section{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding-top: 36px;
}

.purchaseBnz-bottom-section-header-container{
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-family: Inter;
  font-size: 18px;
  font-weight: 700;
  text-align: left;
  color: #4E4B59;
}

.purchaseBnz-bottom-section-inner-wrapper{
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  padding: 10px 0;
}

.purchaseBnz-bottom-section-left-container{
  width: 40%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  gap: 20px;
  padding: 10px 0;
}

.purchaseBnz-payment-type-container{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.purchaseBnz-payment-type-field-label{
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  text-align: left;
  color: #4E4B59;
  padding-left: 16px;
}

.purchaseBnz-bottom-section-right-container{
  width: 55%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}

.purchaseBnz-bottom-section-installments{
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding-top: 14px;
  gap: 16px;
}



.purchaseBnz-add-cards{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  gap: 10px;
  padding-top: 16px;
}

.purchaseBnz-cards-section-container{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}

.purchaseBnz-cards-section-upper-container{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  gap: 16px;
}

.purchaseBnz-cards-section-middle-container{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  gap: 16px;
  padding-top: 10px;
}

.purchaseBnz-cards-section-lower-container{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  gap: 16px;
  padding-top: 16px;
}

.purchaseBnz-cards-section-field-label{
  font-family: Inter;
  font-size: 14px;
  font-weight: 700;
  text-align: left;
  color: #4E4B59;
  padding-bottom: 5px;
}

.purchaseBnz-detectadblock-section{
  font-family: 'Inter';
  font-weight: 700;
  font-size: 14px;
  display: flex;
  align-items: center;
  color: #F14F5C;
  padding-top: 30px;
}

.purchaseBnz-button-container{
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
  padding-top: 24px;
}
.purchaseBnz-main-button.css-1e6y48t-MuiButtonBase-root-MuiButton-root, .purchaseBnz-main-button.css-1e6y48t-MuiButtonBase-root-MuiButton-root:hover{
  font-family: 'Inter';
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  text-align: center;  
  color: #FFFFFF;
  background-color: #F14F5C;
  border-radius: 4px;
  border: 2px solid #F14F5C;
  padding: 6px 12px;
  line-height: unset;
  letter-spacing: unset;
  text-transform: unset;
}
.purchaseBnz-secondary-button.css-1e6y48t-MuiButtonBase-root-MuiButton-root, .purchaseBnz-secondary-button.css-1e6y48t-MuiButtonBase-root-MuiButton-root:hover{
  font-family: 'Inter';
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  text-align: center;  
  color: #F14F5C;
  background-color: #FFFFFF;
  border-radius: 4px;
  border: 2px solid #F14F5C;
  padding: 6px 12px;
  line-height: unset;
  letter-spacing: unset;
  text-transform: unset;
}
.purchaseBnz-payment-type-radio-button.css-vqmohf-MuiButtonBase-root-MuiRadio-root{
  padding: 0;
  margin: 0;
  color: #CCCCCC;  
}

.purchaseBnz-coupon-container{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  font-family: 'Inter';
  font-weight: 700;
  font-size: 14px;
  color: #4E4B59;
  padding: 3px 0;
  gap: 3px;  
}

.purchaseBnz-coupon-field{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  font-family: 'Inter';
  font-weight: 700;
  font-size: 14px;
  color: #4E4B59;
  padding: 3px 0;
  gap: 5px;  
}
