.purchase-data-privacy-wrapper {
  width: 100%;
  background: #FFFFFF;
  padding: 40px;
}
.purchase-data-privacy-inner-wrapper{
  width: 100%;
  min-height: 0;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 24px;
  gap: 115px;
  background: #FFFFFF;
  border-radius: 16px;
  filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.16));
}
.purchase-data-privacy-left-container{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  font-family: 'Inter';
  font-weight: 400;
  font-size: 18px;
  color: #4E4B59;
}
.purchase-data-privacy-right-container{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding-top: 60px;
}
.purchase-data-privacy-box-plans{
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  border: 3px solid;
  border-radius: 16px;
  margin-top: 30px;
  padding: 20px 16px 12px 24px;
  font-family: 'Montserrat';
  gap: 20px;
}
.purchase-data-privacy-box-plans h2{
  font-weight: 600;
  font-size: 24px;
  color: #4E4B59;
  margin: 0;
  padding: 0;
}
.purchase-data-privacy-box-plans h4{
  font-weight: 400;
  font-size: 14px;
  color: #9997A0;
  margin: 0;
  padding: 0;
}
.purchase-data-privacy-box-plans p{
  font-weight: 500;
  font-size: 16px;
  color: #4E4B59;
  margin: 0;
  padding: 7px 0 0 0;
}
.purchase-data-privacy-box-plans-section-left{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  white-space: nowrap;
}
.purchase-data-privacy-box-plans-section-right{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  white-space: nowrap;
}
.purchase-data-privacy-left-container-bottom-section{
  font-size: 16px;
  gap: 7px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-top: 20px;
}
.purchase-data-privacy-payment-wrapper{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}
.purchase-data-privacy-payment-wrapper-header{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-family: 'Inter';
  font-weight: 700;
  font-size: 18px;
  color: #4E4B59;
}
.purchase-data-privacy-payment-wrapper-middle{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}
.purchase-data-privacy-payment-wrapper-type-select{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-top: 10px;
  font-family: 'Inter';
  font-weight: 600;
  font-size: 16px;
  color: #4E4B59;
}
.purchase-data-privacy-creditcard-wrapper{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}
.purchase-data-privacy-creditcard-select{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding-top: 20px;
  font-family: 'Inter';
  font-weight: 600;
  font-size: 16px;
  color: #4E4B59;
}
.purchase-data-privacy-add-card-container{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding-top: 20px;
}
.purchase-data-privacy-add-card-fields-container{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  gap: 16px;
}
.purchase-data-privacy-add-card-field{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  font-family: 'Inter';
  font-weight: 700;
  font-size: 14px;
  color: #4E4B59;
  padding: 3px 0;
  gap: 3px;
}
.purchase-data-privacy-payment-disclaimer{
  padding-top: 16px;
  font-family: 'Inter';
  font-style: italic;
  font-weight: 400;
  font-size: 14px;
  color: #4E4B59;
}
.purchase-data-privacy-payment-buttons-container{
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  grid-gap: 16px;
  gap: 16px;
  padding-top: 20px;
}
.purchase-data-privacy-payment-new-creditcard{
  padding-top: 16px;
}
.purchase-data-privacy-link-button{
  background: transparent;
  border: none;
  text-decoration: underline;
  font-family: 'Inter';
  font-weight: 400;
  font-size: 16px;
  color: #4E4B59;
  margin: 0;
  padding: 0;
}
.purchase-data-privacy-link-button-secondary{
  background: transparent;
  border: none;
  font-family: 'Montserrat';
  font-weight: 700;
  font-size: 14px;
  color: #2C4474;
  margin: 0;
  padding: 8px 0 0 0;
}
.purchase-data-privacy-wrapper-active-subscription{
  width: 100%;
  min-height: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 40px;
  gap: 15px;
  background: #FFFFFF;
  border-radius: 16px;
  filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.16));
}
.purchase-data-privacy-detectadblock-section{
  font-family: 'Inter';
  font-weight: 700;
  font-size: 14px;
  display: flex;
  align-items: center;
  color: #F14F5C;
  padding: 15px 0;
}

.purchase-data-privacy-coupon-container{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  font-family: 'Inter';
  font-weight: 700;
  font-size: 14px;
  color: #4E4B59;
  padding: 3px 0;
  gap: 3px;  
}
.purchase-data-privacy-coupon-field{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  font-family: 'Inter';
  font-weight: 700;
  font-size: 14px;
  color: #4E4B59;
  padding: 3px 0;
  gap: 5px;  
}








/* font-family: 'Inter';
font-style: normal;
font-weight: 700;
font-size: 14px;
line-height: 150%;
display: flex;
align-items: center;
color: #4E4B59; */



















.purchase-data-privacy-title{
  width: 100%;
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 700;
  text-align: left;
  padding: 0;
  margin: 0;
  color: #4E4B59;
}
.purchase-data-privacy-header-section{
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
  flex-wrap: wrap;
}
.purchase-data-privacy-header-section-left-container{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 21px;
  padding-top: 16px;
}
.purchase-data-privacy-header-section-inner-wrapper{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}
.purchase-data-privacy-header-section-field-label{
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  text-align: left;
  color: #4E4B59;
}
.purchase-data-privacy-header-section-field-helper-text{
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  text-align: left;
  color: #D3D2D7;
}
.purchase-data-privacy-header-section-right-container{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;  
  background: #FAFAFA;
  border-radius: 12px;
  padding: 15px 56px 22px 21px;
}
.purchase-data-privacy-header-section-right-container-title{
  font-family: Inter;
  font-size: 16px;
  font-weight: 700;
  text-align: left;
  color: #4E4B59;
}
.purchase-data-privacy-header-section-right-container-price{
  font-family: Inter;
  font-size: 24px;
  font-weight: 400;
  text-align: left;
  color: #F14F5C;
  padding: 17px 14px 0 0;
}
.purchase-data-privacy-middle-section{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding-top: 26px;
  font-family: Inter;
  font-size: 18px;
  font-weight: 700;
  text-align: left;
  color: #4E4B59;
  gap: 5px;
}
.purchase-data-privacy-middle-section-inner-wrapper{
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  gap: 10px;
}
.purchase-data-privacy-middle-section-left-container{
  width: 45%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}
.purchase-data-privacy-middle-section-right-container{
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  color: #FFFFFF;
  background: #7683F6;
  border-radius: 16px;
  padding: 12px 24px 0 24px;
}
.purchase-data-privacy-package-description-text{
  font-family: Inter;
  font-weight: 400;
  font-size: 16px;
  text-align: left;
  padding-bottom: 12px;
}
.purchase-data-privacy-inner-container{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding-top: 36px;
  font-family: 'Inter';
  font-weight: 700;
  font-size: 18px;
  color: #4E4B59;
}
.purchase-data-privacy-inner-container-header{
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-family: Inter;
  font-size: 18px;
  font-weight: 700;
  text-align: left;
  color: #4E4B59;
}
.purchase-data-privacy-bottom-section-inner-wrapper{
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  padding: 10px 0;
}
.purchase-data-privacy-bottom-section-left-container{
  width: 40%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  gap: 20px;
  padding: 10px 0;
}
.purchase-data-privacy-type-container{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.purchase-data-privacy-type-field-label{
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  text-align: left;
  color: #4E4B59;
  padding-left: 16px;
}
.purchase-data-privacy-bottom-section-right-container{
  width: 55%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}
.purchase-data-privacy-bottom-section-installments{
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding-top: 14px;
  gap: 16px;
}
.purchase-data-privacy-add-cards{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  gap: 10px;
  padding-top: 16px;
}
.purchase-data-privacy-cards-section-container{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}
.purchase-data-privacy-cards-section-upper-container{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  gap: 16px;
}
.purchase-data-privacy-cards-section-middle-container{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  gap: 16px;
  padding-top: 10px;
}
.purchase-data-privacy-cards-section-lower-container{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  gap: 16px;
  padding-top: 16px;
}
.purchase-data-privacy-cards-section-field-label{
  font-family: Inter;
  font-size: 14px;
  font-weight: 700;
  text-align: left;
  color: #4E4B59;
  padding-bottom: 5px;
}
.purchase-data-privacy-button-container{
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
  padding-top: 24px;
}
.purchase-data-privacy-main-button.css-1e6y48t-MuiButtonBase-root-MuiButton-root, .purchase-data-privacy-main-button.css-1e6y48t-MuiButtonBase-root-MuiButton-root:hover{
  font-family: 'Inter';
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  text-align: center;  
  color: #FFFFFF;
  background-color: #44E47A;
  border-radius: 4px;
  border: 2px solid #44E47A;
  padding: 6px 12px;
  line-height: unset;
  letter-spacing: unset;
  text-transform: unset;
}
.purchase-data-privacy-secondary-button.css-1e6y48t-MuiButtonBase-root-MuiButton-root, .purchase-data-privacy-secondary-button.css-1e6y48t-MuiButtonBase-root-MuiButton-root:hover{
  font-family: 'Inter';
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  text-align: center;  
  color: #4E4B59;
  background-color: #FFFFFF;
  border-radius: 4px;
  border: 2px solid #4E4B59;
  padding: 6px 12px;
  line-height: unset;
  letter-spacing: unset;
  text-transform: unset;
}
.purchase-data-privacy-type-radio-button.css-vqmohf-MuiButtonBase-root-MuiRadio-root{
  padding: 0;
  margin: 0;
  color: #CCCCCC;  
}