.my-services {
    width: 80%;
    margin: auto;
    margin-top: 30px;
}

.my-services h4 {
    color: #000000;
    margin-bottom: 40px;
}

.my-services .items {
    display: flex;
}

.my-services .items .item {
    position: relative;
    width: 28%;
    border: 1px solid #D3D2D7;
    min-height: 420px;
    border-radius: 16px;
    margin-right: 25px;
}

.my-services .items .item img {
    text-align: left;
    width: 64px;
    margin-top: 20px;
    margin-left: 20px;
}

.my-services .item h5 {
    margin-left: 20px;
    margin-top: 30px;
    color: #000000;
    font-family: 'Inter';
    font-weight: 600;
    font-size: 24px;
}

.my-services .text {
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 30px;
    color: #000000;
    font-family: 'Inter';
    font-size: 18;
}

.my-services button {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 90%;
    background-color: #F14F5C; 
    color: #FFFFFF;
    margin-bottom: 20px;
    border: none;
    padding: 10px;
    text-align: left;
    border-radius: 8px;
    font-size: 20px;
    font-weight: 500;
    display: inline-flex;
    align-items: center;
}

.my-services button img {
    margin-left: 8px;
    font-size: 18px;
    margin-top: 0px !important;
    width: 24px !important;
    right: 0;
    position: absolute;
}