.transactions-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}
.transactions-inner-wrapper-header-section {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  color: #4E4B59;
}
.transactions-inner-wrapper-header-title{
  width: 100%;
}
.transactions-inner-wrapper-container-filters{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
}
