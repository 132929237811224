.countProposals{
    background: #F14F5C;
    font-size: 10px;
    font-weight: bold;
    color: #fff;
    font-weight: bold;
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 5px 10px;
    border-radius: 50%;
    font-size: 14px;
    font-weight: bold;
}