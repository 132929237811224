.swiper {
  width: 100%;
  height: 240px;
  margin-right: 15px;
}

#notice-carrousel {
  background-size: cover;
  border-radius: 16px;
  background-color: #FFFFFF;
  color: #000000;
  border: 1px solid #D3D2D7;
}
.swiper-slide {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: flex-start;
}

.swiper-slide.swiper-slide-active {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: flex-start;
}

.swiper-slide.swiper-slide-active p {
  font-size: 22px !important;
  text-align: left;
  margin-left: 30px;
  color: #fff;
  font-weight: bold;
}

.swiper-slide.swiper-slide-active button {
  background: #1A2C50;
  border: 0;
  height: 50px;
  margin-right: 30px;
  color: #fff;
  font-weight: bold;
  border-radius: 10px;
  padding: 0px 20px;
  border: none;
  text-align: left;
  display: inline-flex;
  align-items: center;
  margin-top: 75px;
  margin-left: 40px;
}


.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: transparent;
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  margin-top: 40px;
}

div#bnz-current-balance {
  margin-top: 30px;
  text-align: left;
}

div#bnz-current-balance-content {
  display: flex;
  font-size: 28px;
  color: #f14f5c;
}

div#bnz-current-balance-content img {
  margin-left: 20px;
  width: 35px;
}

div#bnz-current-balance-content {
  margin-right: 40px;
}

div#bnz-current-balance-content h4 {
  margin: 20px
}

div#bnz-current-balance small {
  margin-right: 65px;
}

:root {
  --swiper-theme-color: #fff;
}

span.swiper-pagination-bullet {
  float: left;
  font-size:32px;
}

.swiper-pagination.swiper-pagination-bullets.swiper-pagination-horizontal {
  margin-left: 30px;
}
