@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');

:root {
    --dk-font: #4E4B59;
    --red-bnz: #F14F5C;
    --purple-bnz: #7683F6;
}

body {
    background-color: #FFFFFF;
}

h1, h2, h3, h4, h6, th, strong{
    color: var(--dk-font);
    font-family: 'Inter', sans-serif;
}

.sm-txt-info {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #9997A0;
}

.modal {
    border-radius: 16px;
}
.box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 400px;
    background-color: white;
    border: none !important;
    padding: 20px;
    border-radius: 16px;
    overflow: auto;
}
.box:focus-visible {
    outline: none !important;
}

/* Modal Sizes */
.md-size-6 {
  width: 1024px;
  height: 768px;
}

.md-size-5 {
    width: 910px;
    height: 464px;
}
.md-size-4 {
    width: 458px;
    min-height: 257px;
    max-height: 501px;
}


.md-size-3 {
    width: 807px;
    height: 351px;
}

.md-size-2 {
    width: 807px;
    height: 253px;
}

.md-size-1 {
    width: 574px;
    height: 346px;
}

.btn:hover {
    color: white !important;
}
.css-1gywuxd-MuiInputBase-root-MuiOutlinedInput-root {
    border: solid 1px #D3D2D7;
    border-radius: 12px !important;
}

input[type="text"], .input {
    border: 1px solid #D3D2D7;
    box-sizing: border-box;
    border-radius: 8px;
    font-family: 'Inter';
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    width: 100%;
    padding: 11px;
    height: 40px !important;
    color: #9997A0;
}

/* Select React */
.select-container, .select__control,
.select__value-container, .select__indicators,
.select__input-container, .select__placeholder {
    height: 40px !important;
}

.btn-red {
    background: var(--red-bnz);
    border-radius: 4px;
    padding: 8px 4px;
    color: white;
    border: none;
    font-weight: 700;
}
.btn.btn-concluid {
    background: #44E47A;
    border: solid 2px #44E47A;
    border-radius: 4px;
    padding: 8px 4px;
    color: white;
    font-weight: 700;
}
.btn.btn-concluid:hover {
    background: #3dcf6e;
    color: white;
}
.btn.btn-out-red {
    background: transparent ;
    border-radius: 4px;
    padding: 8px 4px;
    color: #F14F5C ;
    border: 2px solid #F14F5C ;
    font-weight: 700;
}
.btn.btn-red {
    background: #F14F5C ;
    border-radius: 4px;
    padding: 8px 4px;
    color: #fff;
    border: 2px solid #F14F5C ;
    font-weight: 700;
}
.btn.btn-red:hover{
    background: #F14F5C ;
    border-radius: 4px;
    padding: 8px 4px;
    color: #fff;
    border: 2px solid #F14F5C ;
    font-weight: 700;
}
.btn.btn-out-red:hover {
    background: #F14F5C;
    color: white;
    border: 2px solid #F14F5C;
}

.btn.btn-blue, .btn.btn-blue:hover {
  background: #2D4677;
  border-radius: 4px;
  color: white;
  padding: 8px 4px;
  text-transform: none;
}

.btn.btn-cancel {
    background: transparent;
    border-radius: 4px;
    padding: 8px 4px;
    color: #9997A0;
    border: 2px solid #9997A0;
    font-weight: 700;
}
.btn.btn-cancel:hover{
    background: transparent;
    border-radius: 4px;
    padding: 8px 4px;
    color: #9997A0 !important;
    border: 2px solid #9997A0;
    font-weight: 700;
}
.dropzone {
    border: none !important;
    border-radius: 8px;
    padding: 1.6em 1em;
    color: #545454;
    opacity: 1;
    margin-bottom: 1em;
    background: #EAEAEA;
}
.btn.btn-blue {
    background: #1A2C50;
    border-radius: 4px;
    padding: 8px 4px;
    color: white;
    border: none;
    font-weight: 700;
}
.btn.btn-purple {
    background: #6741A3;
    border-radius: 4px;
    padding: 8px 4px;
    color: white;
    border: none;
    font-weight: 700;
}
.btn.btn-out-blue, .btn.btn-out-blue:hover {
    background: transparent;
    border-radius: 4px;
    padding: 8px 4px;
    color: #1A2C50 !important;
    border: 2px solid #1A2C50;
    font-weight: 700;
}

.btn-wd-1 {
    width: 162px;
}

.icon {
    width: 32px;
    height: 32px;
    margin: 0 !important;
}
.icon.good {
    background-image: url('../assets/icons/good.svg');
}
.icon.atencion {
    background-image: url('../assets/icons/atencion.svg');
}
.icon.danger {
    background-image: url('../assets/icons/cancel.svg');
}
.icon.ongoing {
  background-image: url('../assets/icons/ongoing.svg');
}

.table-d {
    max-height: 70vh;
    overflow: auto;
    border-radius: 16px !important;
}
.table-d th{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    text-transform: none;
    color: #4E4B59;
}
.table-d td{
    font-family: 'Inter';
    font-weight: 400;
    font-size: 16px;
    color: #9997A0;
}
.table-d span{
    cursor: pointer;
}
/* .hmhCjM {
    color: green !important;
    background: transparent !important;
    border: none !important;
}
.MjYbE {
    background: transparent !important;
    border: none !important;
}
.MjYbE, .hmhCjM {
    background: transparent !important;
    border: none !important;
} */

.card-info {
    padding: 11px 20px;
    align-items: center;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row !important;
    width: 100%;
}
.card-info p{
    margin: 0;
    font-size: 14px;
    color: #9997A0;
    margin-left: 16px;
}
.card-info .icon{
    height: 28px;
    width: 28px;
    margin-right: 16px !important;
}

.whatsapp {
    position: absolute !important;
    right: 0 !important;
    bottom: 50px !important;
}

.whatsapp {
    margin-right: 20px;
    margin-bottom: 20px;
}

.credits-balloon-wrapper {
    margin-right: 7px;
    margin-bottom: 25px;
}

.baloons-info {
    display: flex;
    margin-top: auto;
    justify-content: space-around;
    width: 80%;
}
.baloons-info .baloon{
    padding: 13px 24px;
    max-width: 230px;
    margin: 0 auto;
    height: 128px;
}
.baloons-info .baloon .ver-mais{
    color: #7683F6;
    cursor: pointer;
    position: absolute;
    bottom: 10px;
    right: 10px;
}
.baloons-info .baloon h4{
    font-size: 18px;
}

.purple-baloon {
    background: #7683F6;
    border-radius: 16px;
    color: white;
}
.purple-baloon strong {
    color: white;
}
.tooltip-inner, .tooltip-arrow, .bs-tooltip-top .tooltip-arrow::before{
  background-color: #5A67DF !important;
  border-top-color: #5A67DF !important;
}

.carousel-control-next, .carousel-control-prev {
  width: 3% !important;
}

.ajs-message, .ajs-dialog {
    font-size: 20px;
}

.ajs-ok {
    background-color: #4CAF50 !important;
    color: white !important;
    border: none !important;
    padding: 5px 5px !important;
    cursor: pointer !important;
}

.ajs-cancel {
    background-color: #E3E3E3 !important;
    color: black !important;
    border: none !important;
    padding: 5px 5px !important;
    cursor: pointer !important;
}

.ajs-ok:hover {
    background-color: #45a049 !important;
}

.ajs-cancel:hover {
    background-color: #C3C3C3 !important;
}

#base-layout > div > div > button[color='primary'] {
    display: none !important;
}

#base-layout > div > div > header {
    display: none !important;
}

#base-layout .MuiBox-root:not(.container-messages-chat ) {
    width: unset;
}

div#base-layout > div:nth-child(2) > div:nth-child(1) .plan {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 4px 8px;
    gap: 16px;

    height: 32px;

    border: 1px solid #085C6E;
    color: #085C6E;
    border-radius: 32px;

    flex: none;
    order: 0;
    flex-grow: 0;
    font-size: 16px;
    line-height: 24px;
    font-family: 'Inter';
}

.breadCrumb {
    border-bottom: 1px solid #D3D2D7;
    padding-left: 20px;
}

.breadCrumb button {
    color: #9997A0 !important;
    font-family: 'Inter';
    font-size: 14px;
    font-weight: 500;
}

.breadCrumb svg {
    color: #000000 !important;
}