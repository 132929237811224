.container-hints {
  width: 100%;
  background-color: #FFFFFF;
  border-radius: 16px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
}
.inner-container-hints {
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 12px 24px;
  font-size: 14px;
  color: #9997A0;
  margin: 0;
}
.inner-container-hints p{
  margin: 0;
}
.spacer-hints {
  width: 4px;
  height: 32px;
  margin: 0 10px 0 24px;
  background-color: #7683F6;
  border-radius: 16px;
}