.box-chat{
  background-image: none !important;
  width: 40vw;
  max-height: 50vh;
  min-height: 100px !important;
  box-shadow: 0px 0px 10px rgb(0 0 0 / 10%);
  border-radius: 8px;
}
.container-chat{
  display: flex;
  max-height: 40vh;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 16px 24px;
}

#file-demand{
  display: none !important;
}

.container-header-chat{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid #D3D2D7;
  padding-bottom: 10px;
}
.chat-lawyer-name{
  color: #4E4B59;
  font-size: 16px;
  font-weight: 600;
}
.chat-button-close button{
  background-color: transparent;
  border: none;
  color: #7683F6;
  font-size: 16px;
  font-weight: 600;
  font-style: italic;
  cursor: pointer;
}
.box-chat .MuiOutlinedInput-root{
  width: 37vw;
  padding: 0 16px;
  border-radius: 8px;
  line-height: 0;
}
.box-chat .MuiOutlinedInput-input{
  padding: 11px 0;
  font-size: 14px;
  font-weight: 400;
}
.box-chat .css-vwk53d-MuiInputBase-root-MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline{
  border-color: #D3D2D7;
}
.box-chat .container-messages-chat-reverse{
  width: 100%;
  height: auto;
  display: flex;
  gap: 12px;
  flex-direction: column;
  padding: 12px 0 56px 0;
}
.box-chat .container-messages-chat{
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column-reverse;
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow-y: scroll;
}
.box-chat .container-messages-chat::-webkit-scrollbar {
  display: none;
}
.box-chat .message-createdAt{
  max-width: 20%;
  color: #FFFFFF;
  background-color: #7683F6;
  border-radius: 16px;
  text-align: center;
  font-size: 10px;
  font-weight: 400;
  padding: 5px 10px;
  align-self: center;
}
.box-chat .message-lawyer-container{
  max-width: 70%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  color: #D3D2D7;
  text-align: left;
  font-size: 10px;
  font-weight: 400;
}
.box-chat .message-lawyer{
  color: #4E4B59;
  background-color: #FAFAFA;
  border-radius: 8px;
  text-align: left;
  font-size: 14px;
  font-weight: 400;
  padding: 12px 16px;
  margin-left: 5px;
  align-self: flex-start;
  overflow-wrap: break-word;
}
.box-chat .message-client-container{
  max-width: 70%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
  color: #D3D2D7;
  text-align: left;
  font-size: 10px;
  font-weight: 400;
  align-self: flex-end;
}
.box-chat .message-client{
  color: #4E4B59;
  background-color: #D3FBE5;
  border-radius: 8px;
  text-align: left;
  font-size: 14px;
  font-weight: 400;
  padding: 12px 16px;
  margin-right: 5px;
  align-self: flex-end;
  overflow-wrap: break-word;
}
.box-chat .close-icon-chat{
  width: 10px;
  height: 10px;
  margin-right: 5px;
}
.box-chat .close-icon-chat path{
  stroke: #7683F6;
}
.no-messages-yet {
  text-align: center;
  height: 60vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
button.message-attatch {
  padding: 5px 10px;
  margin-bottom: 10px;
  border: 1px solid #d1d1d1;
  border-radius: 30px;
}
.MuiOutlinedInput-root.MuiInputBase-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.MuiInputBase-adornedEnd.css-1glan3o-MuiInputBase-root-MuiOutlinedInput-root {
  height: 150px;
  border: 0;
}

.message-client-attatchment {
  margin-right:10px;
}

input#outlined-adornment-message {
  height: 150px !important;
  background: transparent;
  border: 0;
  align-items: self-start;
}

.MuiFormControl-root.css-1nrlq1o-MuiFormControl-root {
  border: 1px solid #D3D2D7;
  border-radius: 13px;
}

.message-client-container.MuiBox-root.css-0 {
  display: flex;
  font-size: 10px;
  flex-direction: row;
  align-content: center;
  align-items: center;
  justify-content: flex-end;
  margin-right: 5px;
}

.message-client.MuiBox-root.css-0 {
  background: #d3fbe5;
  padding: 10px 15px;
  border-radius: 10px;
  font-size: 17px;
  margin-right: 20px;
}

.container-messages-chat-reverse.MuiBox-root.css-0 {
  height: 55vh;
  margin-bottom: 10px;
  overflow: auto;
  flex-direction: column;
  justify-content: flex-end;
}

.message-lawyer-container.MuiBox-root.css-0 {
  display: flex;
  font-size: 9px;
  flex-direction: row;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  margin-right: 5px;
}

.message-lawyer.MuiBox-root.css-0 {
  background: #F14F5C;
  color:#fff;
  padding: 10px 15px;
  border-radius: 10px;
  font-size: 17px;
  margin-right: 20px;
}
