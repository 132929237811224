.box-cards-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}
.box-cards-header-section {
  font-family: 'Inter';
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  text-align: left;
  color: #4E4B59;
  padding-bottom: 8px;
}
.box-cards-client-cards-wrapper{
  display: flex;  
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 60px;
  flex-wrap: wrap;
}
.box-cards-client-cards-container{
  width: 328px;
  height: 200px;
  background: url("../../images/bg_clientCard.png");
  background-repeat: no-repeat;
  background-position: center center;
  padding: 0 0 10px 25px;
  border-radius: 8px;
  box-sizing: border-box;
}
.box-cards-client-cards-new-card-container{
  width: 328px;
  height: 200px;
  background-color: #FFFFFF;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  box-sizing: border-box;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.08);  
}
.box-cards-client-cards-details-card-number{
  display: flex;  
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  font-family: "IBM Plex Mono";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  padding-top: 10px;
  color: #FFFFFF;
  text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
}
.box-cards-client-cards-details-card-expire{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  font-family: "IBM Plex Mono";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  color: #FFFFFF;
  padding: 7px 25px 5px 25px;
  text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);  
}
.box-cards-client-cards-details-card-name{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  font-family: "IBM Plex Mono";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  color: #FFFFFF;
  text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.box-cards-client-cards-remove{
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  box-sizing: border-box;
  margin: -22px -22px;
}
.box-cards-client-cards-details-card-brand{
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  box-sizing: border-box;
  padding: 19px 25px;
}
.box-modal {
  min-width: 50vw;
  max-height: 60vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  gap: 20px;
  overflow: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #FFFFFF;
  box-shadow: 0px 0px 10px rgb(0 0 0 / 10%);
  padding: 32px;
  border-radius: 8px;
}
.box-cards{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}
.box-client-cards{
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  border-top: 1px solid #D3D2D7;
  padding-top: 10px;
  padding-bottom: 20px;
  font-family: 'Inter';
  font-size: 16px;
  font-weight: 600;
  text-align: left;
  color: #4E4B59;
}
.box-client-cards:last-child {
  border-bottom: 1px solid #D3D2D7;
}
.box-client-cards-details{
  padding-right: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: 'Inter';
  font-size: 16px;
  font-weight: 600;
  text-align: left;
  color: #4E4B59;
}
.box-add-cards{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  gap: 16px;
}
.box-cards-title{
  font-family: 'Montserrat';
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  text-align: left;
  color: #4E4B59;  
}
.box-cards-section-title{
  font-family: 'Inter';
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  text-align: left;
  color: #4E4B59;
  padding-top: 30px;
}
.box-cards-fields-title{
  font-family: 'Inter';
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  text-align: left;  
}
.box-cards-button-container{
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
  padding-top: 24px;
}
.box-cards-section-container{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}
.box-cards-section-field-label{
  font-family: Inter;
  font-size: 14px;
  font-weight: 700;
  text-align: left;
  color: #4E4B59;
  padding-bottom: 5px;
}
.box-cards-confirm-button.css-1e6y48t-MuiButtonBase-root-MuiButton-root, .box-cards-confirm-button.css-1e6y48t-MuiButtonBase-root-MuiButton-root:hover{
  font-family: 'Inter';
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  text-align: center;  
  color: #FFFFFF;
  background-color: #F14F5C;
  border-radius: 4px;
  border: 2px solid #F14F5C;
  padding: 6px 12px;
  line-height: unset;
  letter-spacing: unset;
  text-transform: unset;
}
.box-cards-cancel-button.css-1e6y48t-MuiButtonBase-root-MuiButton-root, .box-cards-cancel-button.css-1e6y48t-MuiButtonBase-root-MuiButton-root:hover{
  font-family: 'Inter';
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  text-align: center;  
  color: #F14F5C;
  background-color: #FFFFFF;
  border-radius: 4px;
  border: 2px solid #F14F5C;
  padding: 6px 12px;
  line-height: unset;
  letter-spacing: unset;
  text-transform: unset;
}
.box-cards-delete-card-container{
  min-width: 15vw;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  gap: 25px;
  overflow: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #FFFFFF;
  box-shadow: 0px 0px 10px rgb(0 0 0 / 10%);
  padding: 32px;
  border-radius: 8px;
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  text-align: left;
  color: #4E4B59;
}
.box-cards-delete-card-inner-container{
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 20px;
}