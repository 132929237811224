.custom-table-proposals-wrapper{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.custom-table-proposals-table{
  width: 100%;
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  color: #9997A0;
  background-color: #FFFFFF;
  border-radius: 16px;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
}

.custom-table-proposals-thead{
  font-family: Inter;
  font-size: 18px;
  font-weight: 700;
  text-align: left;
  color: #4E4B59;
  background-color: #FAFAFA;
}

.custom-table-proposals-thead tr th{
  padding: 18px 20px;
}

.custom-table-proposals-thead tr th:first-child{
  padding: 18px 41px;
  text-align: center;
}

.custom-table-proposals-thead tr th:last-child{
  padding: 18px 30px;
  text-align: center;
}

.custom-table-proposals-tbody tr{
  border-top: 1px solid #E0E0E0;
}

.custom-table-proposals-tbody tr:last-child{
  border-top: 1px solid #E0E0E0;
  border-bottom: 1px solid #E0E0E0;
}

.custom-table-proposals-tbody tr td{
  padding: 10px 20px;
  text-align: left;
}

.custom-table-proposals-tbody tr td button{
  text-align: left;
  border: none;
  background-color: transparent;
  color: #9997A0;
  margin: 0;
  padding: 0;
}

.custom-table-proposals-tbody tr td:first-child{
  text-align: center;
}

.custom-table-proposals-tbody tr td:last-child{
  text-align: center;
  padding: 10px 30px;
}

.custom-table-proposals-tfoot{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: #9997A0;
}

.custom-table-proposals-tfoot tr td{
  padding: 10px 0;
  text-align: center;
}

.custom-table-proposals-pagination-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.custom-table-proposals-pagination-button-number.css-1e6y48t-MuiButtonBase-root-MuiButton-root{
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  line-height: unset;
  letter-spacing: unset;
  text-transform: unset;
  padding: 4px 0;
  min-width: 30px;
}

.custom-table-proposals-pagination-button-arrow.css-78trlr-MuiButtonBase-root-MuiIconButton-root{
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  line-height: unset;
  letter-spacing: unset;
  text-transform: unset;
  padding: 0;
}




.custom-table-proposals-section-header{
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  text-align: left;
  color: #4E4B59;
  padding: 0 0 8px 0;
  margin: 0;
}

.custom-table-proposals-inner-wrapper{
  height: 512px;
  display: flex;  
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  background: #FFFFFF;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.08);
  padding: 50px 16px 50px 24px;
  border-radius: 8px;
  overflow-y: auto;
}

.custom-table-proposals-purchase-container{
  width: 100%;
  display: flex;  
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  border-top: 1px solid #D3D2D7;
  border-bottom: 1px solid #D3D2D7;
}

.custom-table-proposals-left-container{
  display: flex;  
  flex-direction: column;
  align-items: flex-start;
  padding: 12px 0 38px 0;
}

.custom-table-proposals-right-container{
  display: flex;  
  flex-direction: column;
  align-items: flex-end;
  padding: 20px 0 38px 0;
}

.custom-table-proposals-description-text{
  margin: 0;
  padding: 0;
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  color: #D3D2D7;
}

.custom-table-proposals-text{
  margin: 0;
  padding: 0;
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 700;
  text-align: left;
  color: #4E4B59;
  text-transform: uppercase;
}

.custom-table-proposals-no-purchases{
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  text-align: left;
  color: #4E4B59;
  padding: 0 0 8px 0;
  margin: 0;
  align-self: center; 
  text-align: center;
}

.custom-table-proposals-buttons-container{
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.custom-table-proposals-accept-button.css-1e6y48t-MuiButtonBase-root-MuiButton-root, .custom-table-proposals-accept-button.css-1e6y48t-MuiButtonBase-root-MuiButton-root:hover{
  font-family: 'Inter';
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  text-align: center;  
  color: #FFFFFF;
  background-color: #44E47A;
  border-radius: 4px;
  border: 1px solid #44E47A;
  padding: 6px 12px;
  line-height: unset;
  letter-spacing: unset;
  text-transform: unset;
}

.custom-table-proposals-refuse-button.css-1e6y48t-MuiButtonBase-root-MuiButton-root, .custom-table-proposals-refuse-button.css-1e6y48t-MuiButtonBase-root-MuiButton-root:hover{
  font-family: 'Inter';
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  text-align: center;  
  color: #F14F5C;
  background-color: #FFFFFF;
  border-radius: 4px;
  border: 1px solid #F14F5C;
  padding: 6px 12px;
  line-height: unset;
  letter-spacing: unset;
  text-transform: unset;
}

.no-bids-found{
  pointer-events: none;
}
