:root {
  --bg-nav: #fff;
  --icon-color: #9997A0;
  --icon-active: #F14F5C;
}
.sidebar-menu-main {
  background: #fff !important;
}
.sidebar-menu-main ul li a {
  text-decoration: none !important;
  padding: 10px !important;
  font-size: 16px;
  background: transparent !important;
  color: #9997A0;
  transition: .2s linear;
  display: flex !important;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  justify-content: flex-start;
  align-items: center !important;
  width: 70%;
  margin: 0 auto;
}
.sidebar-menu-main ul li a:hover{
  color: #F14F5C;
  transition:.2s linear;
}

.sidebar-menu-main ul li a p {
  width: 80%;
}

.logo-nav {
  display: flex !important;
  justify-content: center !important;
  border-bottom: 2.5px solid #ef4d5a;
  padding-bottom: 20px;
  width: 80% !important;
  margin: 0 auto !important;
}

.logo-nav img {
  padding: 0;
  margin: 0;
}

.sidebar-menu-main ul li a li {
  width: 100%;
  margin-top: 15px;
}

.sidebar-menu-main ul li a li a {
  display: flex !important;
  justify-content: flex-start;
  align-content: flex-start;
  border-bottom: none !important;
  flex-wrap: nowrap;
  margin-left: 15px;
}

.sidebar-menu-main ul li{
  transition:.2s linear;
}

.sidebar-menu-main ul li:hover {
  background: #ECECEC;
  transition:.2s linear;
}

.sidebar-menu-main ul li a img {
  width: 20px !important;
}

.sidebar-menu-main ul li a:hover img {
  filter: invert(42%) sepia(20%) saturate(7195%) hue-rotate(329deg) brightness(101%) contrast(89%);
}

.sidebar-menu-main ul {
  margin-top: 25px;
}

.sidebar-menu-main {
  box-shadow: 2px 0px 20px 0px;
}


/* New Nav */
.sidebar {
  background-color: white;
}
.nav-left {
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  width: 96px;
  box-shadow: inset -1px 0px 0px #E0E0E0;
  transition: all ease 0.5s;
}
.nav-left.open {
  width: 354px;
}
.nav-left .nav-icon svg path {
  stroke: #4E4B59;
}

.nav-icon.dropdown .subtabs{
  display: none;
  height: 0;
  opacity: 0;
}
.nav-icon.dropdown.open .subtabs {
  display: block;
  height: auto;
  opacity: 1;
}

.nav-left.open button {
  width: 100%;
}
.nav-left.open .logo{
  max-width: 152px;
}
.nav-left .line {
  background-color: var(--icon-active);
  height: 2px;
  width: 80%;
}
.nav-left .nav-icon {
  width: calc(100% - 48px);
  margin-left: 24px;
  margin-bottom: 15px;
  cursor: pointer;
}
.nav-left .nav-icon .icon-container{
  width: 100%;
  padding: 8px;
  display: flex;
  justify-content: center;
}
.nav-left.open .nav-icon .icon-container{
  width: 100%;
  display: flex;  
  flex-direction: row;
  align-items: baseline;
  justify-content: flex-start;
}

.nav-left .link-active .nav-icon {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: flex-start;
  background: #FFEFF0;
  width: calc(100% - 48px);
  text-decoration: none;
  color: var(--icon-active);
  margin-left: 24px;
  border: 1px solid #F14F5C;
  border-radius: 8px;
}
.nav-left .link {
  width: 100%;
  margin-top: 10px;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
}
.nav-left.open .nav-icon p{
  padding-left: 16px;
  white-space: nowrap;
  margin-top: -10px;
  margin-bottom: -10px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 150%;
  letter-spacing: -0.005em;
  color: #4E4B59 !important;
}
.nav-left a {
  text-decoration: none!important;
}

.nav-left button {
  width: 30px;
  height: 30px;
  border: 0;
  background-color: #fff;
  display: block;
  margin: 16px auto;
}
.nav-left .nav-logo {
  height: 60px;
  margin: 10px auto;
}
.nav-left .expandir {
  color: var(--icon-color);
  cursor: pointer;
  font-size: 16px;
  padding-top: 50px;
  padding-bottom: 20px;
}
.nav-left .expandir.recuar{
  display: flex;
  flex-direction: row;
  align-items: center;
  color: var(--icon-color);
  padding-top: 50px;
  padding-bottom: 20px;
}
.nav-left .expandir.recuar p{
  all: unset;
  padding: 0 4px;
}
.nav-left .expandir svg{
  margin: 0 auto;
  display: block;
  height: 20px;
  width: 20px;
}
button#menu-button i {
  font-size: 50px !important;
  padding: 6.1px;
  background: var(--bg-nav);
  display: flex;
  height: 64px;
  justify-content: center;
  align-items: center;
}

.link-active p{
  font-weight: 700 !important;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 150%;
  letter-spacing: -0.005em;
  color: #4E4B59 !important;
}

button.sidebutton {
  position: fixed;
  width: 200px;
  height: 30px;
  left: 100%;
  top: 30%;
  font-weight: bold;
  background-color:#F14F5C;
  color:#fff;
  border:none;
  border-radius:0px 0px 15px 15px;
  transform-origin: 0% 0%;
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg) translateX(-50%);
  z-index: 99;
  padding-bottom: 8px;
}

button.sidebutton-2 {
  position: fixed;
  width: 200px;
  height:45px;
  left: 100%;
  top: 60%;
  font-weight: bold;
  background-color:#F14F5C;
  color:#fff;
  border:none;
  border-radius:0px 0px 15px 15px;
  padding: 1%;
  transform-origin: 0% 0%;
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg) translateX(-50%);
  z-index: 99;
}

div#base-layout > div:nth-child(2) > div:nth-child(2) {
  width: 100% !important;
  margin: 0 auto;
  max-width: 100% !important;
  padding: 0 !important;
}

div#base-layout {
  background-color: #FFF !important;
}

@media all and (max-width: 600px) {
  .nav-left {
    display: none;
  } 

  .nav-left.open {
    display: flex !important;
    position: fixed;
    z-index: 999999;
    background: white;
    height: 100%;
  }
}

.signBlue {
  background-color: rgb(86, 86, 252) !important;
}


.vertical-divider {
  width: 32px;
  height: 0px;
  border: 1px solid #D3D2D7;
  transform: rotate(-90deg);
  align-self: stretch;
  margin-top: 25px;
  margin-left: 15px;
}