.partners-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  background: #FFFFFF;  
  gap: 60px;
  padding: 34px 0;
}
.partners-header-wrapper{
  width: 100%;
  min-height: 217px;
  background: url("../../images/bg_partners.png");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  border-radius: 16px;
  box-sizing: border-box;
  font-family: 'Montserrat';
  text-align: left;
  color: #FAFAFA;
}
.partners-header-left-container{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 5px;
  padding: 35px 0 20px 55px;
}
.partners-header-left-container h2{
  font-family: 'Montserrat';
  color: #FAFAFA;
  font-weight: 700;
  font-size: 32px;
}
.partners-header-left-container span{
  font-family: 'Montserrat';
  color: #FAFAFA;
  font-weight: 400;
  font-size: 24px;
}
.partners-header-right-container{
  width: 246px;
  height: 208px;
  background: url("../../images/owl_partners.png");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  /* display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 35px 0 0 55px;
  border-radius: 16px; */
  box-sizing: border-box;
  align-self: flex-end;

  @media only screen and (max-width: 1200px) {
    display: none;
  }
}



.partners-middle-wrapper{
  width: 100%;
  min-height: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: flex-start;
  gap: 30px;
}
.partners-middle-partner-container{
  max-width: 292px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  font-family: 'Inter';
  color: #4E4B59;
  background: #FFFFFF;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  gap: 20px;
  border-radius: 16px;
  padding: 30px 20px;
}
.partners-middle-partner-container-header{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.partners-middle-partner-container h4{
  padding: 0;
  margin: 0;
  color: #33303E;
  font-weight: 700;
  font-size: 18px;
}
.partners-middle-partner-container span{
  padding: 0 0 10px 0;
  margin: 0;
  color: #44E47A;
  font-weight: 600;
  font-size: 12px;
}
.partners-middle-partner-container p{
  padding: 0;
  margin: 0;
  font-weight: 400;
  font-size: 16px;
  color: #4E4B59;
}

.partners-inner-wrapper-header-section {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  color: #4E4B59;
}
.partners-inner-wrapper-header-title{
  width: 100%;
}
.partners-inner-wrapper-container-filters{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
}
.partners-link-button.css-1e6y48t-MuiButtonBase-root-MuiButton-root, .partners-link-button.css-1e6y48t-MuiButtonBase-root-MuiButton-root:hover{
  font-family: 'Inter';
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  text-align: center;  
  color: #FFFFFF;
  background-color: #F14F5C;
  border-radius: 4px;
  border: 2px solid #F14F5C;
  padding: 6px 12px;
  line-height: unset;
  letter-spacing: unset;
  text-transform: unset;
  align-self: flex-end;
  margin-top: auto;
}