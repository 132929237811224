.servicesBallons-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: stretch;
  padding-top: 30px;
}

.proposals-header-wrapper {
  width: 100%;
  min-height: 0;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: 25px;
} 

.servicesBallons-container {
  width: 15%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: 10px 13px 10px 13px;
  box-sizing: border-box;
  font-family: Inter;
  font-size: 13px;
  text-align: left;
  color: #4E4B59;
  background: #FFFFFF;
  box-shadow: 0px 1px 2px;
  border-radius: 16px;
  gap: 8px;
  font-weight: 400;
  font-size: 12px;
  flex-grow: 1;
  margin: 5px; 
}

.servicesBallons-container h4{
  font-weight: 700;
  font-size: 18px;
}
.servicesBallons-container p{
  margin: 0;
  padding: 0;
}
.servicesBalloons-container-balloons-items{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.servicesBallons-container-button{
  align-self: flex-end;
  font-weight: 700;
  color: #7683F6;
  cursor: pointer;
}
.servicesBalloons-container-dot {
  height: 5px;
  width: 5px;
  background-color: #4E4B59;
  border-radius: 50%;
  display: inline-block;
  margin: 8px 8px;
  flex-shrink: 0;
}

.servicesBallons-container h4 {
  font-weight: 700;
  font-size: 16px;
  color: #F14F5C;
  margin-right: 10px;
} 

.balloon-icon {
  display: flex;
  align-items: right;
  justify-content: center;
  margin-bottom: 0; 
  width: 100%;
}

.minimize-icon {
  width: 15px; 
  height: 15px; 
  display: flex;
  justify-content: center;
  align-items: center;
  fill: #F14F5C;
  align-self: center;
  cursor: pointer;
}

.Mvp{
  width: 100%;
  align-self: center;
}

.arrow-icon {
  font-size: 20px;
  margin-right: 10px;
}

.servicesBallons-container h4 {
  text-align: center;
  display: flex;
  width: 100%;
  justify-content: center;
}

.servicesBalloons-container-balloons-items button {
  background: #fff;
  border: navajowhite;
  text-align: start;
}

.servicesBallons-container.maxwidth {
  max-width: 27.5%;
  min-width: 27.5%;
}