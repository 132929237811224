.orderBnz-wrapper{
  width: 451px;
  height: 210px;
  background-color:#F14F5C;
  background-repeat: no-repeat;
  background-position: center center;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 37px 88px 0 42px;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  box-sizing: border-box;
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  text-align: left;
  color: #FFFFFF;
}
