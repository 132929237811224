.font-link {
  font-family: 'Ubuntu';
}

.wrapper-ame-payment{
  width: 50vw;
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  background-color: #FFFFFF;
  border-radius: 8px;
  margin: 10vh auto;
}

.ame-qrcode-wrapper{
  width: 100%;
  max-width: 340px;
  margin: 40px auto;  
}

.ame-qrcode-wrapper p,
.ame-qrcode-wrapper strong,
.ame-qrcode-wrapper span{
  font-family: 'Ubuntu';
  font-size: 12px;
}

.ame-qrcode-wrapper .logo{
  display: block;
  margin: 0 auto;
}

.ame-qrcode-wrapper .container{
  margin-top: 20px;
  padding: 20px;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 15px;
}

.ame-qrcode-wrapper .payment-values{
  text-align: center
}

.ame-qrcode-wrapper .payment-values strong{
  display: block;
}

.ame-qrcode-wrapper .payment-values strong:first-child{
  margin-bottom: 5px;
}

.ame-qrcode-wrapper .payment-values strong span{
  color: #ED555D;
  font-size: 16px;
}

.ame-qrcode-wrapper .payment-values p strong{
  display: inline;
  color: #4a90e2;
}

.ame-qrcode-wrapper img.qrcode{
  display: block;
  margin: 10px auto;
}

.ame-qrcode-wrapper .payment-info > strong{
  color: #ED555D;
  font-size: 16px;
  text-align: center;
  text-align: justify;
}

.ame-qrcode-wrapper .payment-info ul {
  list-style: none;
  padding: 0;
}

.ame-qrcode-wrapper .payment-info ul li {
  color: #aaaaaa;
font-size: 12px;
  margin-bottom: 10px;
}

.ame-qrcode-wrapper .payment-info ul li p {
  height: 15px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 5px 0;
}

.ame-qrcode-wrapper .payment-info ul li strong, 
.ame-qrcode-wrapper .payment-info ul li img{
  margin: 0 2px
}

.ame-qrcode-wrapper a {
  color: #aaaaaa;
font-size: 12px;
}