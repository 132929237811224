.cm .banner {
  height: 237px;
  width: 100%;
  border-radius: 16px;
  background-size: cover;
  padding: 14px 40px;
  margin-top: 30px;
  background: linear-gradient(92.86deg, #F14F5C -2.7%, #F99094 119.09%);;
  position: relative;
  color: #fff !important;
}

.cm h3 {
  font-weight: 600;
  font-size: 24px;
}
.cm h4 {
  font-weight: 600;
  font-size: 20px;
}
.cm .banner h1 {
  font-weight: 700;
  font-size: 28px;
  line-height: 124%;
}
.cm .banner h1,
.cm .banner p{
  color: #fff !important;
  max-width: 900px;
}

.cm .banner img{
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 0;
}

.cm .card {
  background: #FFFFFF;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
}


.doc{
  background: #FFFFFF;
  border-radius: 16px;
  font-size: 14px;
}

.doc input[type="text"], .doc select{
  font-size: 14px;
  padding: 6px 11px;
  color: #000 !important;
  border-radius: 8px !important;
}

div#no-contract {
  background: #fff;
}

button#delete-contract {
  background: #F14F5C;
  border: none;
  padding: 0;
  color: #fff;
  font-weight: bold;
  padding: 10px 5px;
  border-radius: 5px;
}

.contract-submenu-option {
  text-decoration: none;
  color: #000000;
  background: #fff;
  border: none;
  font-weight: 600;
  border-bottom: .1px solid;
  padding-bottom: 20px;
  font-family: 'Inter';
  font-size: 18px;
}

button.contract-submenu-option {
  padding: 0;
}

.contract-submenu-option:last-child {
  border: 0;
}

a.contract-submenu-option:hover {
  color: #9997A0;
}

.non-visible{
  display: none !important;
}


.full-screen-loading {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background: #fff;
  height: 101vh;
  z-index: 9;
}

.help-block-red {
  color: #dc3545;
}

.textBlack {
  color: #000000;
  font-family: 'Inter';
}

.formBorder {
  padding: 30px;
  border: 1px solid #D3D2D7;
  border-radius: 16px;
}

#document-create {
  color: #000000;
  font-family: 'Inter';
}

.document-table {
  border: none !important;
  box-shadow: none !important;
}

.document-table th {
  background-color: #EDEDED;
  color: #4E4B59;
  border: 1px solid #9997A0;
}

.document-table table {
  border-collapse: collapse; 
}

.document-table tr th {
  border-bottom: none; /* Remove a borda da primeira coluna */
}

.document-table tr th:first-child {
  border-left: none; /* Remove a borda da primeira coluna */
}

.document-table tr th:last-child {
  border-right: none; /* Remove a borda da primeira coluna */
}

.title-card {
  text-transform: capitalize;
}

.submenu-document {
  border-radius: 16px;
  box-shadow: 0px 4px 8px 3px rgba(0, 0, 0, 0.15), 0px 1px 3px rgba(0, 0, 0, 0.3);
}

