.no-padding {
  padding: 0px !important;
}

.docs-card-list{

}


.box-cards-title.docs-card-title {
  background: #FAFAFA;
  padding: 30px 40px;
}

ul.docs-card-list {
  list-style: none;
  padding: 0px 40px;
}

ul.docs-card-list p {
  background: #fff;
  border: none;
  color: #9997A0;
}

button.upload-document-button {
  background: #fff;
  border: none;
}

ul.docs-card-list li {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding-bottom: 10px;
  border-bottom: 1.8px solid gray;
}

span.file-sended {
  background: #d1d1d1;
  margin-left: 15px;
  padding: 5px;
  border-radius: 10px;
}

button.upload-document-button:disabled img {
  filter: opacity(30%)
}

