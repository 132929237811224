#my-bnz-resume{
  width: 100%;
  border: none;
  border-radius: 16px;
  background-color: #F14F5C;
  color: #FFFFFF;
  height: 240px;
  margin-left: 15px;
}

.bnz-resume h2 {
  margin: 40px 0px 0px 40px;
  font-size: 24px;
  color: #FFFFFF;
  font-family: 'Inter';
  font-weight: 700;
}


.bnz-resume button {
  background: #1A2C50;
  border: 0;
  height: 50px;
  margin-right: 30px;
  color: #fff;
  font-weight: bold;
  border-radius: 10px;
  padding: 0px 20px;
}

div#bnz-reserved-balance-content {
  display: flex;
  font-size: 28px;
  color:#f14f5c;
}

div#bnz-reserved-balance-content img {
  margin-left: 20px;
  width: 35px;
}

div#bnz-reserved-balance {
  margin-left: 40px;
}

div#bnz-reserved-balance-content h4 {
  margin: 20px 0px;
}
