.packagesBnz-wrapper{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.packagesBnz-header-section{
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  text-align: left;
  color: #4E4B59;
  padding-bottom: 8px;
  margin: 0;
}

.packagesBnz-hint-section{
  width: 100%;
  margin-bottom: 40px;
}

.packagesBnz-packages-wrapper{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  gap: 16px;
}

.packagesBnz-package-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background: #FFFFFF;
  box-shadow: 0px 2px 10px 2px rgba(0, 0, 0, 0.08);
  border-top: 7px solid;
  border-radius: 8px;
  padding: 14px 24px 24px 24px;
}

.packagesBnz-package-price-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #D3D2D7;
  border-bottom: 1px solid #D3D2D7;
  padding: 14px 20px;
}

.packagesBnz-package-text-small{
  font-family: Montserrat;
  font-size: 10px;
  font-weight: 300;
  text-align: center;
  color: #9997A0;
  padding: 0;
  margin: 0;
}

.packagesBnz-package-text-medium{
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  color: #4E4B59;
  padding: 5px 0;
  margin: 0;
}

.packagesBnz-package-text-big{
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 700;
  text-align: center;
  padding: 0;
  margin: 0;
  color: #4E4B59;
}

.packagesbnz-package-text-bonuses{
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  color: #4E4B59;
  padding-top: 20px;
  margin: 0;
}

.packagesBnz-package-text-contact{
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  color: #4E4B59;
  padding: 15px 0 0 0;
  margin: 0;
}