.client-purchase-wrapper{
  width: 38%;
  height: 100%;  
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  flex-grow: 1;
}

.client-purchase-section-header{
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  text-align: left;
  color: #4E4B59;
  padding: 0 0 8px 0;
  margin: 0;
}

.client-purchase-inner-wrapper{
  height: 734px;
  display: flex;  
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  background: #FFFFFF;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.08);
  padding: 50px 16px 50px 24px;
  border-radius: 8px;
  overflow-y: auto;
}

.client-purchase-purchase-container{
  width: 100%;
  display: flex;  
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  border-top: 1px solid #D3D2D7;
  border-bottom: 1px solid #D3D2D7;
}

.client-purchase-left-container{
  display: flex;  
  flex-direction: column;
  align-items: flex-start;
  padding: 12px 0 38px 0;
}

.client-purchase-right-container{
  display: flex;  
  flex-direction: column;
  align-items: flex-end;
  padding: 20px 0 38px 0;
}

.client-purchase-description-text{
  margin: 0;
  padding: 0;
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  color: #D3D2D7;
}

.client-purchase-text{
  margin: 0;
  padding: 0;
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 700;
  text-align: left;
  color: #4E4B59;
  text-transform: uppercase;
}

.client-purchase-no-purchases{
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  text-align: left;
  color: #4E4B59;
  padding: 0 0 8px 0;
  margin: 0;
  align-self: center; 
  text-align: center;
}