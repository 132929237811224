#service-provider h3{
  text-align: center;
  margin-bottom: 40px;
}

#service-provider #loader {
  height: 300px;
}

div#service-provider #loader .card-body {
  display: flex;
  justify-content: center;
}
