div#service-details .card {
  padding: 30px;
}

div#service-details-buttons {
  text-align: center;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

div#service-details-options-buttons button {
  width: 120px;
}

div#service-details label {
  padding: 0;
}


div#service-details h3 {
  text-align: center;
  margin-bottom: 40px;
}

div#service-details-options-buttons {
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
}
