.modal-refuse-proposal {
    background-image: url("../../../../images/bg-refuse-proposal.png");
    background-size: cover;
    border-radius: 8px 8px 16px 16px;
    width: 573px !important;
    height: 368px;
    text-align: center;
    padding: 0 !important;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: none !important;
}
.css-1wnsr1i {
    border: none !important;
}
.modal-refuse-proposal h2 {
    font-family: Montserrat;
    font-size: 20px;
    font-weight: 700;
    text-align: center;
    color: white;
    display: block;
    margin: 36px auto 0 !important;
}
.modal-refuse-proposal h2 strong {
    font-weight: 700;
}
.modal-refuse-proposal img {
    width: 220px;
    height: 177px;
}
.modal-refuse-proposal p{
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    margin: 10px auto 0 !important;
}
.modal-refuse-proposal .x-icon {
    position: absolute;
    top: 17px;
    right: 17px;
    width: 23px;
    height: 23px;
    color: white;
    cursor: pointer;
}

.modal-refuse-proposal-buttons-container{
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 16px;
  margin: 30px auto;
}

.modal-refuse-proposal-refuse-button.css-1e6y48t-MuiButtonBase-root-MuiButton-root, .modal-refuse-proposal-refuse-button.css-1e6y48t-MuiButtonBase-root-MuiButton-root:hover{
  width: 162px;
  font-family: 'Inter';
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  text-align: center;  
  color: #FFFFFF;
  background-color: #44E47A;
  border-radius: 4px;
  border: 1px solid #44E47A;
  padding: 6px 12px;
  line-height: unset;
  letter-spacing: unset;
  text-transform: unset;
}

.modal-refuse-proposal-reject-button.css-1e6y48t-MuiButtonBase-root-MuiButton-root, .modal-refuse-proposal-reject-button.css-1e6y48t-MuiButtonBase-root-MuiButton-root:hover{
  width: 162px;
  font-family: 'Inter';
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  text-align: center;  
  color: #9997A0;
  background-color: #FFFFFF;
  border-radius: 4px;
  border: 1px solid #9997A0;
  padding: 6px 12px;
  line-height: unset;
  letter-spacing: unset;
  text-transform: unset;
}