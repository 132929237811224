.border-0.m-0.p-0.left-bordered.col-8 {
  border-left: 1px solid #D3D2D7 !important;
  position: relative;
}
.border-0.m-0.p-0.left-bordered.col-8 .bullet {
  position: absolute;
  left: -4px;
  margin: 0px !important;
}

.border-0.m-0.p-0.left-bordered.col-8 span {
  margin-left: 10px;
}

div#demand-sidebar {
  height: 92vh;
  border-right: 1px solid #D3D2D7;
}

div#base-layout > div:nth-child(2) > div:nth-child(1) {
  border-bottom: 1px solid #D3D2D7;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  padding-right: 5px;
}

div#base-layout > div:nth-child(2) > div:nth-child(1) > div {
  width: 100% !important;
}

.demand-v2-topbar.row {
  border-bottom: 1px solid #D3D2D7;
  display: flex;
  width: 100%;
  padding-bottom: 10px;
  margin-top: 10px;
  padding-left: 20px;
  height: 85px;
}

.demand-v2-topbar.row .col-1, .demand-v2-topbar.row .col-2, .demand-v2-topbar.row .col-3 {
  align-self:center;
  justify-self:start;
}

.container-messages-chat{
  width: 100%;
}

div#demand-sidebar {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 30px;
}

.demand-obs {
  padding: 20px 35px;
}

div#demand-sidebar .title {
  word-break: break-word;
}

div#demand-sidebar .title {
  padding: 0px 20px 30px 0px;
}

.options-container button {
  background: transparent;
  border: none;
  margin-bottom: 10px;
  font-weight:500;
}

.options-container button:hover {
  color:#7683F6;
}

.timeline-item.row {
  min-height: 60px;
}

span.bullet {
  border: 2px solid #f14f5c;
  border-radius: 100%;
  height: 7px;
  width: 7px;
  color: transparent;
  background: #fff;
}

div#demand-sidebar hr {
  width: 82%;
}

.timeline-item.row {
}

.timeline-container {
  margin-top: 35px;
  margin-bottom: 40px;
}

.message-createdAt.MuiBox-root.css-0 {
  text-align: center;
  background: #7683f6;
  width: 70px;
  margin: 0 auto;
  height: 20px;
  color: #ffff;
  font-size: 10px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.demand-awnsers {
  margin: 20px 35px;
}

#demand-requests {
  width: 100%;
  max-height: 90vh;
  border-radius: 8px;
  overflow-y: auto;
  padding: 20px 35px;
}

.demand-request {
  width: 100%;
  background-color: #FFFFFF;
  border-radius: 16px;
  min-height: 100px;
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.18);
  padding: 16px 16px 16px 16px;
  margin-bottom: 20px;
}

.demand-request-header {
  margin-bottom: 28px;
}

.demand-request-item {
  border-bottom: 1px solid #D3D2D7;
  padding: unset;
}

.demand-request-item-document-title {
  font-size: 16px;
  font-weight: 600;
  color: #4E4B59;
  font-family: 'Montserrat';
}

.demand-request-item-document {
  margin-bottom: 30px;
}

.demand-request-item-document-comment {
  font-size: 12px;
  font-weight: normal;
  color: #F14F5C;
  font-family: 'Montserrat';
}

.demand-request .title {
  font-family: 'Montserrat';
  font-size: 24px;
  font-weight: 600;
}

.demand-request .debit-add {
  color: #9997A0;
  font-family: 'Inter';
  font-size: 14px;
}

.demand-request .value-bnz {
  color: #F14F5C;
  font-family: 'Inter';
  font-size: 20px;
  font-weight: bold;
}

.demand-request-item-footer-model {
  font-family: 'Inter';
  font-size: 12px;
  color: #9997A0;
}

.demand-request-item-footer {
  padding-bottom: 20px;
}

.demand-request-item-footer-button {
  display: flex;
  align-items: center;
  justify-content: center;
}

.demand-request-btn-grey, .demand-request-btn-grey:hover {
  background-color: #D3D2D7 !important;
  color: #33303E !important;
  font-family: 'Inter' !important;
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: .375rem .75rem;
  font-size: 1rem;
  border-radius: .25rem;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.demand-request-btn-blue, .demand-request-btn-blue:hover {
  background-color: #7683F6 !important;
  color: #FFFFFF !important;
  font-family: 'Inter' !important;
  display: inline-block;
  font-weight: bold;
  line-height: 1.5;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: .375rem .75rem;
  font-size: 1rem;
  border-radius: .25rem;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.demand-request-btn-black, .demand-request-btn-black:hover {
  background-color: #000000 !important;
  color: #FFFFFF !important;
  font-family: 'Inter' !important;
  display: inline-block;
  font-weight: bold;
  line-height: 1.5;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: .375rem .75rem;
  font-size: 1rem;
  border-radius: .25rem;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.dropzone-hide{
  display: none !important;
}
