ul.modal-list {
  list-style: none;
  padding: 0;
}


ul.modal-list li {
  margin: 20px auto;
  font-size: 18px;
  background: #550e33 linear-gradient(89deg,#550e33 0%,#ff4f5d 100%) 0% 0% no-repeat padding-box;
  padding: 15px 30px 15px 30px;
  border-radius: 10px;
}

ul.modal-list li a {
  color: #fff;
  text-decoration: none;
  background-color: rgba(255,255,255,0.1);
  padding: 16px 20px;
  color: #fff;
  display: block;
  -webkit-text-decoration: none;
  text-decoration: none;
  cursor: pointer;
  transition: 0.3s ease-out;
}

ul.modal-list li a:hover {
  transition: 0.3s ease-out;
  background-color: rgba(255,255,255,0.2);
}

#chart{
  height: 400px;
  width: 700px;
}

#dognohut{
  height: 400px;
  width: 400px;
}

#line{
  height: 400px;
  width: 700px;
}

#docs-list{
  width: 400px;
  max-width: 100%;
}

ul.dash-list {
  list-style: none;
  padding-left: 0;
}

ul.dash-list li {
  margin-bottom: 10px;
  font-size: 18px;
  border-bottom: 1.5px solid;
  padding: 12px 0px;
}


ul.dash-list li a {
  text-decoration: none;
  color: #000;
  transition: 0.3s linear;
}

ul.dash-list li a:hover {
  font-size: 26px;
  transition: 0.3s linear;
}

button.refetchWallet {
  padding: 10px;
  margin-left: 5px;
  border: 2px solid #009900;
  border-radius: 8px;
  color: #fff;
  background: #009910;
  margin-bottom: 20px;
  cursor: pointer;  
}
