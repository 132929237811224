.rounded-icon {
  border: 2.5px solid;
  border-radius: 100%;
}

div#bonuz-sign > .card {
    width: 80%;
    margin: 0 auto;
}

.bonuz-sign-primary {
    transition:0.2s linear!important;
    background: #5A67DF!important;
    color: #fff !important;
    font-weight: bold !important;
    padding: 10px 22px!important;
    border-radius: 10px!important;
}

.bonuz-sign-primary:hover {
    transition:0.2s linear;
    background: #5a67df96;
    color: #fff !important;
    font-weight: bold;
    padding: 10px 22px;
    border-radius: 10px;
}

.file-sended-list{
  list-style: none;
}

.overlined-title{
  border-bottom: 1px solid #D3D2D7;
  padding-bottom: 10px;
  margin-bottom: 20px;
}

.active-menu {
  color: #5A67DF;
  font-weight:bold;
}

.active-menu.col svg {
  color: #5A67DF;
}

.non-current-page {
  display: none;
}

.current-page {
  width: 85%;
  margin: 0 auto;
}

.signatories-menu {
  background: rgba(241,79,82,0.15);
  margin-top: 20px;
  margin-bottom: 15px;
}

.signatories-menu button {
  background: transparent;
  border: none;
  padding: 15px;
}

.signatory.row {
  margin: 10px 0 15px 0;
  border-top: 1px solid #D3D2D7;
  padding-top: 20px;
  padding-bottom: 10px;
  text-transform: capitalize;
}

.signatory.row .col-1 {
  display: flex;
  justify-content: center;
  align-items: center;
}

div#broadcast-message .card-body {
    text-align: center;
    margin: 0 auto;
    width: 95%;
}

div#broadcast-message .card-body svg {
    margin: 0 auto;
}

div#success-screen img {
    width: 200px;
    margin: 50px auto;
}

div#success-screen {
    text-align: center;
}

div#success-screen .card {
    min-height: 80vh;
}

div#success-screen p {
    margin-bottom: 60px;
}

div#success-screen button {
    width: 10%;
    margin: 0 auto;
}

.settings-box.row {
    border-bottom: 1px solid #D3D2D7;
    padding: 20px;
}


/*File sender*/
.dropzone.dropzone-bonuz-sign{
    border: 2px dashed #ef4d5a !important;
    background-color: rgba(241,79,92,0.2);
}
