.status-chip {
    height: 32px;
    width: max-content;
    border-radius: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 8px;
}
.status-chip.in_progress {
    color: #5A67DF;
    background-color: #D6DAFF;
}
.status-chip.pending {
    color: #350A7C;
    background-color: #E9D3FB;
}
.status-chip.canceled {
    color: #C31E1E;
    background-color: #F8D0D0;
}
.status-chip.done {
  color: #49A96A;
  background-color: #D6FBD3;
}
.status-chip.concluido {
    color: #49A96A;
    background-color: #D6FBD3;
}
.status-chip.draft {
    color: #FF6007;
    background-color: #FFE7D6;
}
.status-chip.stand_by {
    color: #F4C51F;
    background-color: #FFF9D7;
}
.status-chip.in_estimating {
  color: #D4169F;
  background-color: #FFD7F4;
}
.status-chip.waiting_approval {
  color: #247687;
  background-color: #D3F4FB;
}
.status-chip.in_review {
  color: #68480A;
  background-color: #FBEDD3;
}
.status-chip.demand_sent {
  color: #3AC59B;
  background-color: #D3FBE5;
}
.status-chip.waiting_estimate_approval {
  color: #D4169F;
  background-color: #FFD7F4;
}
.status-chip.waiting_lawyer_acceptance {
  color: #D4169F;
  background-color: #FFD7F4;
}