#demand-info-main-container {
    border: 1px solid #D3D2D7;
    border-radius: 16px;
    width: 100%;
    height: 78vh;
    margin-top: 20px;
    padding: 20px;
}

#demand-info-main-container table {
    justify-content: center;
    align-items: flex-start;
    padding: 0px;
    border-radius: 8px;
    width: 100%;
}

#demand-info-main-container table thead {
    background-color: #D3D3D3;
}

#demand-info-main-container table thead tr th {
    font-family: 'Inter';
    font-size: 16px;
    color: #4E4B59;
    font-weight: 500;
    padding: 8px 16px 8px 16px;
}

#demand-info-main-container table thead tr th:first-child {
    border-top-left-radius: 8px;
}

#demand-info-main-container table thead tr th:last-child {
    border-top-right-radius: 8px;
}

#demand-info-main-container table tbody {
    background-color: #EDEDED;
}

#demand-info-main-container table tbody tr td {
    font-family: 'Inter';
    font-size: 13px;
    color: black;
    font-weight: 500;
    padding: 8px 16px 8px 16px;
}

#demand-info-main-container table tbody tr td:first-child {
    border-bottom-left-radius: 8px;
}

#demand-info-main-container table tbody tr td:last-child {
    border-bottom-right-radius: 8px;
}

#demand-info-main-container .actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    max-width: 15%;
    margin-top: 20px;
}

#demand-info-main-container .actions .icon {
    font-size: 18px;
    padding: 5px;
}

#demand-info-main-container .divider {
    width: 100%;
    border: none;
    border-bottom: 1px solid #D3D2D7;
    margin-top: 20px;
    margin-bottom: 20px;
}

#demand-info-main-container .list-status, 
#demand-info-main-container .demand-info, 
#demand-info-main-container .demand-requests {
    border: 1px solid #D3D2D7;
    border-radius: 16px;
    min-height: 120px;
    max-height: 55vh;
    overflow: auto;
    padding: 10px;
}

#demand-info-main-container .chat {
    border: 1px solid #D3D2D7;
    border-radius: 16px;
    height: 100%;
}

#demand-info-main-container .status-container {
    position: relative;
    width: 100%;
    height: 75px;
}

#demand-info-main-container .circle {
    position: absolute;
    top: 2px; /* Ajuste conforme necessário */
    left: 126px; /* Ajuste conforme necessário */
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: white;
    border: 4px solid #F14F5C;
}

#demand-info-main-container .line {
    position: absolute;
    top: 14px; /* Ajuste conforme necessário */
    left: 131px; /* Ajuste conforme necessário */
    width: 2px;
    height: 60px;
    background-color: #9997A0;
    border-radius: 1px;
}

#demand-info-main-container .data {
    position: absolute;
    top: 22px; /* Ajuste conforme necessário */
    left: 5px; /* Ajuste conforme necessário */
    width: 130px; /* Ajuste conforme necessário */
    min-height: 50px; /* Ajuste conforme necessário */
    background-color: transparent;
    margin-left: 10px;
}

#demand-info-main-container .text {
    position: absolute;
    top: 22px; /* Ajuste conforme necessário */
    left: 160px; /* Ajuste conforme necessário */
    min-height: 50px; /* Ajuste conforme necessário */
    background-color: transparent;
}

#demand-info-main-container .circle-old {
    position: absolute;
    top: 2px; /* Ajuste conforme necessário */
    left: 126px; /* Ajuste conforme necessário */
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: white;
    border: 4px solid #FFA8AF;
}

#demand-info-main-container .list-status .more {
    width: 100%;
    text-align: center;
    color: #F14F5C;
    font-family: 'Inter';
    font-weight: 500;
    font-size: 16px;
    cursor: pointer;
}

.popover {
    background-color: #595c5f !important;
}

.popover-body {
    color: #FFFFFF !important;
}

.popover .popover-arrow {
    display: block;
    opacity: 0.75;
}

.popover[x-placement^=top]>.popover-arrow:after {
    border-top-color: #212529;
}

.popover[x-placement^=bottom]>.popover-arrow:after {
    border-bottom-color: #212529;
}

.popover[x-placement^=right]>.popover-arrow:after {
    border-right-color: #212529;
}

.popover[x-placement^=left]>.popover-arrow:after {
    border-left-color: #212529;
}

#demand-info-main-container .demand-requests.empty,
#demand-info-main-container .list-status.empty {
    display: flex;
    justify-content: center;
    align-items: center;
}

#demand-info-main-container .demand-request-title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 150%;
    letter-spacing: -0.005em;
    color: #000000;
}

#demand-info-main-container .demand-request-pending-documents .demand-request-document {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 16px;
    gap: 16px;
    border: 1px solid #D3D2D7;
    border-radius: 8px;
    margin-top: 12px;
    margin-bottom: 12px;
}

#demand-info-main-container .demand-request-pending-documents .demand-request-document.demand-reproved {
    border: 2px dashed #F14F5C !important;
}

#demand-info-main-container .demand-request-waiting-documents .demand-request-document {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 16px;
    gap: 16px;
    border: 2px dashed #F8AE3F;
    border-radius: 8px;
    margin-top: 12px;
    margin-bottom: 12px;
}

#demand-info-main-container .demand-request-done-documents .demand-request-document {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 16px;
    gap: 16px;
    border: 2px dashed #44E47A;
    border-radius: 8px;
    margin-top: 12px;
    margin-bottom: 12px;
}

#demand-info-main-container .demand-request-pending-documents .demand-request-document .description {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 150%;
    letter-spacing: -0.005em;
    color: #33303E;
    width: 80%;
}

#demand-info-main-container .demand-request-pending-documents .demand-request-document.demand-reproved .description {
    color: #F14F5C !important;
}

#demand-info-main-container .demand-request-waiting-documents .demand-request-document .description {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 150%;
    letter-spacing: -0.005em;
    color: #F8AE3F;
    width: 80%;
}

#demand-info-main-container .demand-request-done-documents .demand-request-document .description {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 150%;
    letter-spacing: -0.005em;
    color: #44E47A;
    width: 80%;
}

#demand-info-main-container .demand-request-pending-documents .demand-request-document button,
#demand-info-main-container .demand-request-waiting-documents .demand-request-document button,
#demand-info-main-container .demand-request-done-documents .demand-request-document button {
    margin-left: 10px;
    width: 10%;
}

#demand-info-main-container .demand-requests .debit-add {
    color: #9997a0;
    font-family: "Inter";
    font-size: 14px;
}

#demand-info-main-container .demand-requests .value-bnz {
    color: #f14f5c;
    font-family: "Inter";
    font-size: 20px;
    font-weight: 700;
}

.btn-empty::after {
    display: none !important;
}